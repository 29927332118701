import React, { useState, useEffect } from "react";
import "./CustomerDetails.css";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { customerListAPI } from "../../../Config/apiConfig";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CustomerDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //States
  const [userId, setUserId] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [verify, setVerify] = useState("");
  const [role, setRole] = useState("");
  const [created, setCreated] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");

  const customerId = useSelector((state) => state.custId.customerId);
  console.log("customer id", customerId);

  useEffect(() => {
    const fetchCustomerListAPI = async () => {
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData && parsedData.token) {
        try {
          const response = await axios.get(customerListAPI + customerId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            console.log("Customer", response.data.user);
            setUserId(response.data.user._id);
            setFname(response.data.user.fname);
            setLname(response.data.user.lname);
            setEmail(response.data.user.email);
            setVerify(response.data.user.regOtpVerified);
            setRole(response.data.user.role);
            setCreated(response.data.user.createdAt);
            setStatus(response.data.user.status);
            setType(response.data.user.typeOfUser);

            // setCustomer();
          }
        } catch (error) {
          console.error("Error fetching supplier list", error);
        }
      }
    };
    fetchCustomerListAPI();
  }, []);

  return (
    <>
      <Box margin="0.5rem 1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Header
              title="Customer Details"
              subtitle="Welcome to your Customer Details"
            />
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "1rem",
                fontWeight: "bold",
                padding: "0.1remem 0.2rem",
                textTransform: "none",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              Dashboard
            </Button>
          </div>
        </Box>
        <Box
          width="100%"
          height="auto"
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          gap="10px"
          margin="0.5rem 1rem"
          m="2rem 0 0 0"
          padding="40px 20px"
          border="2px solid #70D8BD"
          borderRadius="10px"
          position="relative"
        >
          <Typography sx={{ fontSize: 18 }} className="border-text">
            User Id:&nbsp;&nbsp;
            <span
              style={{
                fontSize: 20,
                color: "#70D8BD",
              }}
            >
              {userId}
            </span>
          </Typography>
          <div className="d-flex flex-row gap-5">
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              First Name:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {fname}
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Last Name:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {lname}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Email:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {email}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Type Of User:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {type}
              </span>
            </Typography>
          </div>
          <div className="d-flex flex-column gap-2">
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Registered Verification:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {verify}
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Role:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {role}
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Status:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {status}
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              Created At:&nbsp;&nbsp;
              <span
                style={{
                  fontSize: 20,
                  color: "#70D8BD",
                }}
              >
                {created}
              </span>
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CustomerDetails;
