import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Backdrop,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { loginAPI } from "../../../Config/apiConfig";
import axios from "axios";

export default function Topbar() {
  // Modal state
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const modalRef = useRef(null);

  // token state
  const [isToken, setisToken] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const handleLoginClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    if (email === "" || password === "") {
      setError("Both fields are required!");
      return;
    }

    const mergeData = { email, password };

    try {
      const response = await axios.post(loginAPI, mergeData);
      if (response && response.status === 200) {
        sessionStorage.setItem(
          "adminLoginCredentials",
          JSON.stringify(response.data)
        );
        setisToken(true);
        setOpenModal(false); // Close the modal on successful login
        window.location.reload();
      } else {
        setError("Login failed! Please check your credentials.");
      }
    } catch (error) {
      setError("An error occurred during login. Please try again.");
    }
  };
  const handleLoginOutClick = () => {
    sessionStorage.removeItem("adminLoginCredentials");
    window.location.reload();
  };

  useEffect(() => {
    const loginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData && parsedData.token) {
      setisToken(true);
      setOpenModal(false); // Close the modal if already logged in
    } else {
      setOpenModal(true); // Keep modal open if not logged in
    }
  }, []);

  const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    backdropFilter: "blur(8px)", // Adds a blur effect
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Dimmed background
  }));

  const handleDialogClose = (event, reason) => {
    // Prevent closing the modal when typing in text fields
    if (event.target.closest("input") || event.target.closest("textarea")) {
      return; // Don't close the modal if focus is inside input/textarea
    }

    // If it's a backdrop click or escape key event, close the modal
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      handleCloseModal();
    }
  };
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase type="text" placeholder="Search.." sx={{ ml: 2, flex: 1 }} />
        <IconButton sx={{ p: 1 }} type="button">
          <SearchIcon />
        </IconButton>
      </Box>

      <Box display="flex" gap={2}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlined />
          ) : (
            <DarkModeOutlined />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>

        {!isToken ? (
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "0.1rem 0.2rem",
              textTransform: "none",
            }}
            onClick={handleLoginClick}
          >
            Login
          </Button>
        ) : (
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "0.2rem 0.6rem",
              textTransform: "none",
            }}
            onClick={handleLoginOutClick}
          >
            Log Out
          </Button>
        )}
      </Box>

      {/* Admin Login Modal */}
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDialogClose(event, reason); // Close only for other reasons like escapeKeyDown
          }
        }}
        disableEscapeKeyDown
        keepMounted
        slots={{
          backdrop: CustomBackdrop,
        }}
        ref={modalRef}
      >
        <DialogTitle
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#FCFCFC" : "#141B2D",
            color: theme.palette.mode === "light" ? "#000" : "#fff",
          }}
        >
          Admin Login
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "2rem 1rem",
            backgroundColor:
              theme.palette.mode === "light" ? "#FCFCFC" : "#141B2D",
            color: theme.palette.mode === "light" ? "#000" : "#fff",
          }}
        >
          <TextField
            variant="filled"
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            sx={{
              gridColumn: "span 6",
              backgroundColor:
                theme.palette.mode === "light" ? "#EDEDED" : "#1D2A3D",
              borderRadius: "4px",
              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? "#000" : "#fff",
              },
            }}
          />
          <TextField
            margin="dense"
            variant="filled"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
            sx={{
              gridColumn: "span 6",
              backgroundColor:
                theme.palette.mode === "light" ? "#EDEDED" : "#1D2A3D",
              borderRadius: "4px",
              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? "#000" : "#fff",
              },
            }}
          />
          {error && (
            <Box
              sx={{ color: "red", marginTop: "0.5rem", fontSize: "0.875rem" }}
            >
              {error}
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#FCFCFC" : "#141B2D",
            color: theme.palette.mode === "light" ? "#000" : "#fff",
          }}
        >
          <Button
            onClick={handleSubmit}
            color="primary"
            sx={{ color: theme.palette.mode === "light" ? "#000" : "#fff" }}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
