import React, { useState, useEffect } from "react";
import { Box, useTheme, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { sliderAPI, sliderDeleteAPI } from "../../../Config/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function Slider() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // States
  const [sliderList, setSliderList] = useState([]);

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);

    if (!parsedData) {
      navigate("/"); // Redirect to home page if parsedData is not found
    }
  }, [navigate]);

  const fetchSliderAPI = async () => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData && parsedData.token) {
      try {
        const response = await axios.get(sliderAPI, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response) {
          const sliderWithId = response.data.data.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
          // console.log("Product List", response.data);
          setSliderList(sliderWithId);
        }
      } catch (error) {}
    } else {
      navigate("/"); // Redirect to login if token is missing
    }
  };

  useEffect(() => {
    fetchSliderAPI();
  }, []);

  const handleDeleteSlider = async (id) => {
    console.log(id);
    if (id) {
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData && parsedData.token) {
        try {
          const response = await axios.delete(sliderDeleteAPI + id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            console.log(response.data);
            toast.warn(response.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (error) {}
      } else {
        navigate("/");
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No.",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "path",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        return <img src={params.row.path} style={{ width: "50px" }} />;
      },
    },

    {
      field: "route",
      headerName: "Route",
      flex: 1,
    },
    {
      field: "slidefor",
      headerName: "Slide For",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{ textTransform: "none", backgroundColor: "#ff2752" }}
            onClick={() => {
              handleDeleteSlider(params.row._id);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];
  return (
    <Box m="0.5rem 1rem">
      <div className="d-flex justify-content-between align-items-center">
        <Header title="Slider" subtitle="Managing the team members" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "0.1remem 0.2rem",
            textTransform: "none",
          }}
          onClick={() => {
            navigate("/slider-add");
          }}
        >
          Add Slider
        </Button>
      </div>
      <Box
        margin="0.5rem 1rem"
        m="2rem 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={sliderList}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
}

export default Slider;
