import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import {
  categoryListAPI,
  categorySubCateAPI,
  categoryStatusAPI,
} from "../../../Config/apiConfig";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Categories = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(max-width: 600px)");

  // States
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryList, setSubcategoryList] = useState([]);

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (!parsedData) {
      navigate("/");
    }
  }, [navigate]);

  const fetchCategoriesName = async () => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData) {
      try {
        const response = await axios.get(categoryListAPI, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response.data && response.status === 200) {
          setCategoryName(response.data.category);
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchCategoriesName();
  }, []);

  const handleCategoryId = (e) => {
    setCategoryId(e.target.value);
  };

  const fetchCategoriesListAPI = async () => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData) {
      try {
        const response = await axios.get(categorySubCateAPI + categoryId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response && response.status === 200) {
          console.log("data", response.data.category);
          const subcategoryWithId = response.data.category.map(
            (item, index) => ({
              ...item,
              id: index + 1,
            })
          );

          setSubcategoryList(subcategoryWithId);
        }
      } catch (error) {}
    } else {
      navigate("/"); // Redirect to login if token is missing
    }
  };

  useEffect(() => {
    fetchCategoriesListAPI();
  }, [categoryId]);

  const handleStatus = async (id) => {
    console.log(id);
    const userId = id;
    if (userId) {
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData.token) {
        try {
          const response = await axios.get(categoryStatusAPI + userId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            window.location.reload();
          }
        } catch (error) {}
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "path",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        return <img src={params.row.path} style={{ width: "50px" }} />;
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            sx={{
              padding: "5px 10px",
              backgroundColor:
                params.value === "Inactive" ? "#00509f" : "#1976d2",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              textTransform: "none",
            }}
            onClick={() => handleStatus(params.row._id)}
          >
            {params.value ? "Active" : "Inactive"}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box m="0.5rem 1rem">
        <div className="d-flex justify-content-between align-items-center">
          <Header title="Categories" subtitle="Managing the categories" />
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "0.1remem 0.2rem",
              textTransform: "none",
            }}
            onClick={() => {
              navigate("/category-add");
            }}
          >
            Add Category
          </Button>
        </div>

        <div className="mt-3">
          <FormControl variant="filled" sx={{ m: 1, minWidth: 500 }}>
            <InputLabel id="demo-simple-select-label">
              Categories Name
            </InputLabel>
            <Select
              margin="dense"
              value={categoryId}
              label="Categories Name"
              onChange={handleCategoryId}
              sx={{ gridColumn: isNonMobile ? undefined : "span 12" }}
              InputLabelProps={{
                style: { color: colors.grey[100] },
              }}
            >
              {categoryName &&
                categoryName.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    <img
                      src={item.path}
                      alt=""
                      style={{ width: 20, height: 20 }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              Please Select Category To View Details
            </FormHelperText>
          </FormControl>
        </div>

        <Box
          margin="0.5rem 1rem"
          m="2rem 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              fontFamily: "'Gilroy-regular', sans-serif",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              fontFamily: "'Gilroy-regular', sans-serif",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
              fontFamily: "'Gilroy-regular', sans-serif",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontFamily: "'Gilroy-regular', sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              fontFamily: "'Gilroy-regular', sans-serif",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={subcategoryList}
            columns={columns}
            getRowId={(row) => row._id}
          />
        </Box>
      </Box>
    </>
  );
};

export default Categories;
