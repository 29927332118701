import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  styled,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { categoryListAPI, categoryAddAPI } from "../../../Config/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const initialValues = {
  title: "",
  files: null,
  parent: null,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CategoryAdd() {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [categoryName, setCategoryName] = useState([]);

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);

    if (!parsedData) {
      navigate("/"); // Redirect to home page if parsedData is not found
    }
  }, [navigate]);

  const fetchCategoriesName = async () => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData) {
      try {
        const response = await axios.get(categoryListAPI, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response.data && response.status === 200) {
          setCategoryName(response.data.category);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
  };

  useEffect(() => {
    fetchCategoriesName();
  }, []);

  const handleFormSubmit = async (values) => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData) {
      try {
        const formData = new FormData();

        // Append the specific fields to formData
        if (values.files && values.files[0]) {
          formData.append("image", values.files[0]);
        }
        formData.append("title", values.title);
        formData.append("parent", values.parent);
        const response = await axios.post(categoryAddAPI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });

        if (response && response.status === 200) {
          console.log(response.data);
          toast.success(response.data.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      navigate("/");
    }
  };

  return (
    <Box m="0.5rem 1rem">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Header title="Add Category" subtitle="Add Your Category" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "0.1rem 0.2rem",
            textTransform: "none",
          }}
          onClick={() => {
            navigate("/category");
          }}
        >
          Go To Category
        </Button>
      </div>

      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <div>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    backgroundColor: "#3E4396",
                    fontSize: 16,
                    textTransform: "none",
                  }}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => {
                      // Ensure single file selection
                      setFieldValue(
                        "files",
                        event.target.files[0] ? event.target.files : null
                      );
                    }}
                  />
                </Button>
                {values.files && (
                  <Typography>
                    {Array.from(values.files)
                      .map((file) => file.name)
                      .join(", ")}
                  </Typography>
                )}
              </div>

              <TextField
                type="text"
                variant="filled"
                label="Title"
                name="title"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
                InputLabelProps={{
                  style: { color: colors.grey[100] },
                }}
              />

              <FormControl variant="filled" fullWidth>
                <InputLabel id="category-select-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  name="parent"
                  value={values.parent}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categoryName.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      <img
                        src={item.path}
                        alt={item.name}
                        style={{ width: 20, height: 20 }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" mt="2rem">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ textTransform: "none", fontSize: 16 }}
              >
                Add New Slide
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default CategoryAdd;
