import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendorProductId: "",
};

const productIdSlice = createSlice({
  name: "vendorProdId",
  initialState,
  reducers: {
    setVendorProductId: (state, action) => {
      state.vendorProductId = action.payload;
    },
  },
});

export const { setVendorProductId } = productIdSlice.actions;
export default productIdSlice.reducer;
