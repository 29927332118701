import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerId: "",
};

const customerIDSlice = createSlice({
  name: "custId",
  initialState,
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
  },
});

export const { setCustomerId } = customerIDSlice.actions;
export default customerIDSlice.reducer;
