import React, { useState, useEffect } from "react";
import { Box, useTheme, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { bidsListAPI, bidsStatusAPI } from "../Config/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function Bids() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // States
  const [sliderList, setSliderList] = useState([]);

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);

    if (!parsedData) {
      navigate("/"); // Redirect to home page if parsedData is not found
    }
  }, [navigate]);

  const fetchBidsListAPI = async () => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData && parsedData.token) {
      try {
        const response = await axios.get(bidsListAPI, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response) {
          console.log("bids", response.data);
          const sliderWithId = response.data.data.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
          // console.log("Product List", response.data);
          setSliderList(sliderWithId);
        }
      } catch (error) {}
    } else {
      navigate("/"); // Redirect to login if token is missing
    }
  };

  useEffect(() => {
    fetchBidsListAPI();
  }, []);

  const handleApprove = async (id) => {
    console.log("Approve", id);
    const reject = "Approved";
    if (id) {
      const params = { id, status: reject };
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.post(bidsStatusAPI, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response && response.status === 200) {
            console.log("Approved", response.data);
            console.log("Product Rejected Successfully", response.data);
            console.log("Product Rejected Successfully", response.data.msg);
            toast.success(response.data.msg);
            // Optionally, update the product list or refresh the table
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {
          console.error("Error rejecting product", error);
        }
      }
    }
  };
  const handleReject = async (id) => {
    console.log("Reject", id);
    const reject = "Rejected";
    if (id) {
      const params = { id, status: reject };
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.post(bidsStatusAPI, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response && response.status === 200) {
            console.log("Rejected", response.data);
            console.log("Product Rejected Successfully", response.data);
            console.log("Product Rejected Successfully", response.data.msg);
            toast.success(response.data.msg);
            // Optionally, update the product list or refresh the table
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {
          console.error("Error rejecting product", error);
        }
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No.",
    },
    {
      field: "childCatName", // child category Name
      headerName: "Child Category Name",
      flex: 1,
      cellClassName: "name-column--cell",
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },

    {
      field: "categoryName",
      headerName: "Services Name",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },

    {
      field: "auction_duration",
      headerName: "Duration",
      flex: 1,
      width: 50,
      minWidth: 75,
      maxWidth: 100,
    },
    {
      field: "budget",
      headerName: "Budget",
      flex: 1,
      width: 50,
      minWidth: 75,
      maxWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      width: 75,
      minWidth: 100,
      maxWidth: 125,
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      width: 50,
      minWidth: 75,
      maxWidth: 100,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      width: 50,
      minWidth: 75,
      maxWidth: 100,
    },
    {
      field: "instruction",
      headerName: "Instruction",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 75,
      minWidth: 100,
      maxWidth: 125,

      renderCell: (params) => (
        <div>
          {params.value === "Approved" ? (
            <p className="m-0">
              <img
                src="/icons/approved.png"
                alt="Approved"
                style={{ width: 16, height: 16 }}
              />{" "}
              Approved
            </p>
          ) : (
            <p className="m-0">
              <img
                src="/icons/rejected.png"
                alt="Approved"
                style={{ width: 16, height: 16 }}
              />{" "}
              Rejected
            </p>
          )}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 150,
      minWidth: 175,
      maxWidth: 225,
      renderCell: (params) => (
        <Box display="flex" gap="0.5rem">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              textTransform: "none",
              cursor: "pointer",
            }}
            onClick={() => handleApprove(params.row._id)}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              textTransform: "none",
              cursor: "pointer",
            }}
            onClick={() => handleReject(params.row._id)}
          >
            Reject
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <Box m="0.5rem 1rem">
      <div className="d-flex justify-content-between align-items-center">
        <Header title="Bids" subtitle="Managing the team members" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "0.1remem 0.2rem",
            textTransform: "none",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Dashboard
        </Button>
      </div>
      <Box
        margin="0.5rem 1rem"
        m="2rem 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={sliderList}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
}

export default Bids;
