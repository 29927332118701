import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supplierId: "",
};

const supplierIDSlice = createSlice({
  name: "vendorId",
  initialState,
  reducers: {
    setSupplierId: (state, action) => {
      state.supplierId = action.payload;
    },
  },
});

export const { setSupplierId } = supplierIDSlice.actions;
export default supplierIDSlice.reducer;
