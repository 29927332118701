import { configureStore } from "@reduxjs/toolkit";
import productVenderIdReducer from "./productIDSlice";
import customerIdReducer from "./customerIDSlice";
import supplierIdReducer from "./supplierIDSlice";

export const store = configureStore({
  reducer: {
    vendorProdId: productVenderIdReducer,
    custId: customerIdReducer,
    vendorId: supplierIdReducer,
  },
});
