import React, { useState, useEffect } from "react";
import "./SupplierDetails.css";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { supplierListAPI } from "../../../Config/apiConfig";
import { useNavigate } from "react-router-dom";
import { suppLabel, suppInfo } from "./supplierStyle";
import axios from "axios";

const SupplierDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //States
  const [suppData, setSuppData] = useState({});
  const [suppProfile, setSuppProfile] = useState([]);

  const supplierId = useSelector((state) => state.vendorId.supplierId);
  console.log("supplier id", supplierId);

  useEffect(() => {
    const fetchCustomerListAPI = async () => {
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData && parsedData.token) {
        try {
          const response = await axios.get(supplierListAPI + supplierId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            console.log("supplier", response.data);
            // console.log("supp data", response.data.data.supplier);
            console.log("supp prof", response.data.data.profileSetup[0]);

            setSuppData(response.data.data.supplier);
            setSuppProfile(response.data.data.profileSetup[0]);

            // setCustomer();
          }
        } catch (error) {
          console.error("Error fetching supplier list", error);
        }
      }
    };
    fetchCustomerListAPI();
  }, []);

  return (
    <>
      <Box margin="0.5rem 1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Header
              title="Supplier Details"
              subtitle="Welcome to your Supplier Details"
            />
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "1rem",
                fontWeight: "bold",
                padding: "0.1remem 0.2rem",
                textTransform: "none",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              Dashboard
            </Button>
          </div>
        </Box>
        <Box
          width="100%"
          height="auto"
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          gap="10px"
          margin="0.5rem 1rem"
          m="2rem 0 0 0"
          padding="40px 20px"
          border="2px solid #70D8BD"
          borderRadius="10px"
          position="relative"
        >
          <Typography sx={{ fontSize: 18 }} className="border-text">
            User Id:&nbsp;&nbsp;
            <span
              style={{
                fontSize: 20,
                color: "#70D8BD",
              }}
            >
              {supplierId}
            </span>
          </Typography>
          <div className=" w-100">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <div className="d-flex flex-row gap-5">
                  <Typography sx={suppLabel}>
                    First Name:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppData.fname}</span>
                  </Typography>
                  <Typography sx={suppLabel}>
                    Last Name:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppData.lname}</span>
                  </Typography>
                </div>
                <div>
                  <Typography sx={suppLabel}>
                    Email:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppData.email}</span>
                  </Typography>
                </div>
                <div className="d-flex flex-column gap-1">
                  <Typography sx={suppLabel}>
                    Username:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppData.username}</span>
                  </Typography>
                  <div className="d-flex flex-row gap-5">
                    <Typography sx={suppLabel}>
                      Role:&nbsp;&nbsp;
                      <span style={suppInfo}>{suppData.role}</span>
                    </Typography>
                    <Typography sx={suppLabel}>
                      Status:&nbsp;&nbsp;
                      <span style={suppInfo}>{suppData.status}</span>
                    </Typography>
                  </div>
                  <Typography sx={suppLabel}>
                    Created At:&nbsp;&nbsp;
                    <span style={suppInfo}>
                      {suppData.createdAt
                        ? new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true, // Displays time in 12-hour format with AM/PM
                          }).format(new Date(suppData.createdAt))
                        : "N/A"}
                    </span>
                  </Typography>
                  {/* <Typography sx={suppLabel}>
                    Website:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppProfile.website}</span>
                  </Typography> */}
                  {/* <Typography sx={suppLabel}>
                    Facebook:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppProfile.facebook}</span>
                  </Typography> */}
                  {/* <Typography sx={suppLabel}>
                    Instagram:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppProfile.instagram}</span>
                  </Typography> */}
                  {/* <Typography sx={suppLabel}>
                    Twitter:&nbsp;&nbsp;
                    <span style={suppInfo}>{suppProfile.twitter}</span>
                  </Typography> */}
                </div>
              </div>
              {/* <div className=" text-center">
                <img
                  src={suppProfile.image}
                  alt="Supplier Image"
                  className="supplier_image"
                />
              </div> */}
            </div>
            <div id="horizontal_line" className="mt-4"></div>
            <div className="my-4">
              <div></div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default SupplierDetails;
