import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/dashboard/gloabal/Topbar";
import Sidebar from "./scenes/dashboard/gloabal/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/Team";
import Invoices from "./scenes/Invoices";
import Contact from "./scenes/Constact";
import Bar from "./scenes/Bar";
import Form from "./scenes/Form";
import Line from "./scenes/Line";
import Pie from "./scenes/Pie";
import Faq from "./scenes/Faq";
import Geography from "./scenes/Geography";
import Calendar from "./scenes/Calendar";

import SupplierList from "./scenes/dashboard/SupplierList/SupplierList";
import CustomerList from "./scenes/dashboard/CustomerList/CustomerList";
import ProductList from "./scenes/dashboard/ProductList/ProductList";
import Slider from "./scenes/dashboard/Sliders/Slider";
import SliderAdd from "./scenes/dashboard/Sliders/SliderAdd";
import Bids from "./scenes/Bids";
import Categories from "./scenes/dashboard/Categories/Categories";
import CategoryAdd from "./scenes/dashboard/Categories/CategoryAdd";
import CustomerDetails from "./scenes/dashboard/CustomerList/CustomerDetails";
import SupplierDetails from "./scenes/dashboard/SupplierList/SupplierDetails";
function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />

              <Route path="/customer" element={<CustomerList />} />
              <Route path="/customer-details" element={<CustomerDetails />} />

              <Route path="/supplier" element={<SupplierList />} />
              <Route path="/supplier-details" element={<SupplierDetails />} />

              <Route path="/slider" element={<Slider />} />
              <Route path="/slider-add" element={<SliderAdd />} />

              <Route path="/category" element={<Categories />} />
              <Route path="/category-add" element={<CategoryAdd />} />

              <Route path="/bids" element={<Bids />} />

              <Route path="/products-list" element={<ProductList />} />

              <Route path="/team" element={<Team />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/form" element={<Form />} />
              <Route path="/line" element={<Line />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/calendar" element={<Calendar />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
