import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  styled,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { sliderAddAPI } from "../../../Config/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const initialValues = {
  title: "",
  files: null,
  body: "",
  route: "",
  sliderfor: "",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function SliderAdd() {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);

    if (!parsedData) {
      navigate("/"); // Redirect to home page if parsedData is not found
    }
  }, [navigate]);

  const handleFormSubmit = async (values) => {
    // Log the values including uploaded files
    console.log("Form Submitted with values:", values);
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData) {
      try {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("body", values.body);
        formData.append("route", values.route);
        formData.append("sliderfor", values.sliderfor);

        // Append files to FormData
        if (values.files && values.files[0]) {
          formData.append("image", values.files[0]);
        }
        const response = await axios.post(sliderAddAPI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response && response.status === 200) {
          // console.log(response.data);
          // console.log(response.data.message);
          toast.success(response.data.message, {
            theme: "colored",
          });
        }
      } catch (error) {}
    } else {
      navigate("/");
    }
  };

  return (
    <Box m="0.5rem 1rem">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Header title="Add Slider" subtitle="Add Your Slider" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "0.1remem 0.2rem",
            textTransform: "none",
          }}
          onClick={() => {
            navigate("/slider");
          }}
        >
          Go To Sliders
        </Button>
      </div>

      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                type="text"
                variant="filled"
                label="Title"
                name="title"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
                InputLabelProps={{
                  style: { color: colors.grey[100] },
                }}
              />
              <div>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    backgroundColor: "#3E4396",
                    fontSize: 16,
                    textTransform: "none",
                  }}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) =>
                      setFieldValue("files", event.target.files)
                    }
                    multiple
                  />
                </Button>
                {values.files && (
                  <Typography>
                    {Array.from(values.files)
                      .map((file) => file.name)
                      .join(", ")}
                  </Typography>
                )}
              </div>
              <TextField
                type="text"
                variant="filled"
                label="Body"
                name="body"
                value={values.body}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.body && Boolean(errors.body)}
                helperText={touched.body && errors.body}
                sx={{ gridColumn: "span 6" }}
                InputLabelProps={{
                  style: { color: colors.grey[100] },
                }}
              />
              <TextField
                type="text"
                variant="filled"
                label="Route"
                name="route"
                value={values.route}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.route && Boolean(errors.route)}
                helperText={touched.route && errors.route}
                sx={{ gridColumn: isNonMobile ? undefined : "span 12" }}
                InputLabelProps={{
                  style: { color: colors.grey[100] },
                }}
              />
              <TextField
                type="text"
                variant="filled"
                label="Slider For"
                name="sliderfor"
                value={values.sliderfor}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.sliderfor && Boolean(errors.sliderfor)}
                helperText={touched.sliderfor && errors.sliderfor}
                sx={{ gridColumn: isNonMobile ? undefined : "span 12" }}
                InputLabelProps={{
                  style: { color: colors.grey[100] },
                }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="2rem">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ textTransform: "none", fontSize: 16 }}
              >
                Add New Slide
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default SliderAdd;
