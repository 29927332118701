import React, { useState, useEffect } from "react";
import { Box, useTheme, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { supplierListAPI, supplierStatusAPI } from "../../../Config/apiConfig";
import { useDispatch } from "react-redux";
import { setVendorProductId } from "../../../redux/productIDSlice";
import { setSupplierId } from "../../../redux/supplierIDSlice";
import axios from "axios";

function SupplierList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  // Supplier's States
  const [users, setUsers] = useState([]);
  const [statusUpdates, setStatusUpdates] = useState({});

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);

    if (!parsedData) {
      navigate("/"); // Redirect to home page if parsedData is not found
    }
  }, [navigate]);

  useEffect(() => {
    const fetchSupplierListAPI = async () => {
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.get(supplierListAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            const usersWithId = response.data.user.map((user, index) => ({
              ...user,
              id: index + 1,
            }));
            console.log("Supplier", response.data);
            setUsers(usersWithId);
          }
        } catch (error) {
          console.error("Error fetching supplier list", error);
        }
      }
    };
    fetchSupplierListAPI();
  }, []);

  const handleStatus = async (id) => {
    console.log(id);

    const userId = id;
    if (userId) {
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData.token) {
        try {
          const response = await axios(supplierStatusAPI + userId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            window.location.reload();
          }
        } catch (error) {}
      }
    }
  };

  const handleProductId = (id) => {
    const newProductId = id;
    dispatch(setVendorProductId(newProductId));
    navigate("/products-list");
  };
  const handleServiceId = (id) => {
    const newProductId = id;
    dispatch(setVendorProductId(newProductId));
    navigate("/services-list");
  };

  const handleDetailsId = (id) => {
    const supplierid = id;
    dispatch(setSupplierId(supplierid));
    navigate("/supplier-details");
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      flex: 1,
    },
    {
      field: "fname",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
      width: 110,
      minWidth: 135,
      maxWidth: 185,
    },
    {
      field: "lname",
      headerName: "Last Name",
      flex: 1,
      cellClassName: "name-column--cell",
      width: 110,
      minWidth: 135,
      maxWidth: 185,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      width: 110,
      minWidth: 135,
      maxWidth: 185,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      width: 140,
      minWidth: 170,
      maxWidth: 220,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      width: 75,
      minWidth: 100,
      maxWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 100,
      minWidth: 125,
      maxWidth: 175,
      renderCell: (params) => (
        <Button
          style={{
            padding: "5px 10px",
            backgroundColor:
              params.value === "Inactive" ? "#00509f" : "#1976d2",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            textTransform: "none",
          }}
          onClick={() => handleStatus(params.row._id)}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: "products",
      headerName: "Products",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => (
        <Button
          style={{
            padding: "5px 10px",
            backgroundColor: "#1976d2",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            textTransform: "none",
          }}
          onClick={() => handleProductId(params.row.companyId)}
        >
          Show Products
        </Button>
      ),
    },

    {
      field: "details",
      headerName: "Details",
      flex: 1,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => (
        <Button
          style={{
            padding: "5px 10px",
            backgroundColor: "#28a745",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            textTransform: "none",
          }}
          onClick={() => handleDetailsId(params.row._id)}
        >
          Show Details
        </Button>
      ),
    },
  ];

  return (
    <Box m="0.5rem 1rem">
      <div className="d-flex justify-content-between align-items-center">
        <Header title="Suppliers" subtitle="Managing the suppliers" />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "0.1remem 0.2rem",
            textTransform: "none",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Dashboard
        </Button>
      </div>
      <Box
        margin="0.5rem 1rem"
        m="2rem 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={users} columns={columns} getRowId={(row) => row._id} />
      </Box>
    </Box>
  );
}

export default SupplierList;
