export const baseUrl = "https://api.eservmarket.com/api";

// Admin Login
export const loginAPI = baseUrl + "/auth/login";

// Supplier List
export const supplierListAPI = baseUrl + "/admin/supplier/";
export const supplierStatusAPI = baseUrl + "/admin/supplier/status/";

// Product List
export const supplierProductListAPI = baseUrl + "/admin/products/";
export const supplierProductStatusAPI = baseUrl + "/admin/products/status/";

// Service List
export const supplierServiceListAPI = baseUrl + "/admin/services/";
export const supplierServiceStatusAPI = baseUrl + "/admin/services/status/";

// Customer List
export const customerListAPI = baseUrl + "/admin/customer/";
export const customerStatusAPI = baseUrl + "/admin/customer/status/";

// Slider
export const sliderAPI = baseUrl + "/admin/slider/all";
export const sliderAddAPI = baseUrl + "/admin/slider/add";
export const sliderDeleteAPI = baseUrl + "/admin/slider/delete/";

// Category List
export const categoryListAPI = baseUrl + "/admin/category/list";
export const categorySubCateAPI = baseUrl + "/admin/category/subcategory/";
export const categoryAddAPI = baseUrl + "/admin/category/add";
export const categoryStatusAPI = baseUrl + "/admin/category/status/";

// Bids
export const bidsListAPI = baseUrl + "/admin/bids";
export const bidsStatusAPI = baseUrl + "/admin/bids/status";
