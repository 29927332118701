import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import {
  supplierProductListAPI,
  supplierProductStatusAPI,
} from "../../../Config/apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import axios from "axios";

function ProductList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [products, setProducts] = useState([]);

  const isNonMobile = useMediaQuery("(max-width: 600px)");

  const vendorProductId = useSelector(
    (state) => state.vendorProdId.vendorProductId
  );

  console.log("id", vendorProductId);

  useEffect(() => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);

    if (!parsedData) {
      navigate("/"); // Redirect to home page if parsedData is not found
    }
  }, [navigate]);

  const fetchProductVendorsAPI = async () => {
    const adminLoginResponse = sessionStorage.getItem("adminLoginCredentials");
    const parsedData = JSON.parse(adminLoginResponse);
    if (parsedData) {
      try {
        const response = await axios.get(
          supplierProductListAPI + vendorProductId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );
        if (response.data && response.status === 200) {
          console.log(response.data);

          const productsIndex = response.data.data.map((user, index) => ({
            ...user,
            id: index + 1,
          }));
          console.log("Supplier", response.data);
          setProducts(productsIndex);
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchProductVendorsAPI();
  }, []);

  const handleApprove = async (id) => {
    console.log("approve", id);
    const approve = "Approved";
    if (id) {
      const params = { id: id, status: approve };
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.post(supplierProductStatusAPI, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            console.log(response);
            toast.success(response.data.msg);
            console.log("Product Approved Successfully", response.data);
            console.log("Product Approved Successfully", response.data.msg);

            setTimeout(() => {
              window.location.reload();
            }, 2000);

            // Optionally, update the product list or refresh the table
          }
        } catch (error) {
          console.error("Error approving product", error);
        }
      }
    }
  };

  const handleReject = async (id) => {
    console.log("reject", id);
    const reject = "Rejected";
    if (id) {
      const params = { id, status: reject };
      const adminLoginResponse = sessionStorage.getItem(
        "adminLoginCredentials"
      );
      const parsedData = JSON.parse(adminLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.post(supplierProductStatusAPI, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response && response.status === 200) {
            console.log(response);
            console.log("Product Rejected Successfully", response.data);
            console.log("Product Rejected Successfully", response.data.msg);
            toast.error(response.data.msg);
            // Optionally, update the product list or refresh the table
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {
          console.error("Error rejecting product", error);
        }
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "mrp",
      headerName: "Market Price",
      flex: 1,
    },
    {
      field: "finalPrice",
      headerName: "Final Price",
      flex: 1,
    },
    {
      field: "stock",
      headerName: "Stock",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1.5,
      renderCell: (params) => (
        <Box display="flex" gap="0.5rem">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "green",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              textTransform: "none",
              cursor: "pointer",
            }}
            onClick={() => handleApprove(params.row._id)}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              textTransform: "none",
              cursor: "pointer",
            }}
            onClick={() => handleReject(params.row._id)}
          >
            Reject
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <Box m="0.5rem 1rem">
      <Header title="Products" subtitle="Managing the products" />

      <div className="mt-3">
        <FormControl variant="filled" sx={{ m: 1, minWidth: 500 }}>
          <InputLabel id="demo-simple-select-label">Categories Name</InputLabel>
          {/* <Select
            margin="dense"
            value={categoryId}
            label="Categories Name"
            onChange={handleCategoryId}
            sx={{ gridColumn: isNonMobile ? undefined : "span 12" }}
            InputLabelProps={{
              style: { color: colors.grey[100] },
            }}
          >
            {categoryName &&
              categoryName.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  <img
                    src={item.path}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {item.name}
                </MenuItem>
              ))}
          </Select> */}
        </FormControl>
      </div>

      <Box
        margin="0.5rem 1rem"
        m="2rem 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            fontFamily: "'Gilroy-regular', sans-serif",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={products}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
}

export default ProductList;
