import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProgressCircle from "../../components/ProgressCircle";
import StatBox from "../../components/StatBox";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import { tokens } from "../../theme";
import {
  supplierListAPI,
  customerListAPI,
  supplierProductListAPI,
} from "../../Config/apiConfig";
import axios from "axios";

function Dashboard() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Supplier's States
  const [supplierLength, setSupplierLength] = useState("");
  const [customerLength, setCustomerLength] = useState("");
  const [productsListLength, setProductsListLength] = useState("");

  // useEffect(() => {
  //   const fetchSupplierListAPI = async () => {
  //     try {
  //       const response = await axios.get(supplierListAPI);
  //       if (response && response.status === 200) {
  //         const usersLength = response.data.user.length;
  //         console.log(response.data.user.length);
  //         setSupplierLength(usersLength);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching supplier list", error);
  //     }
  //   };
  //   fetchSupplierListAPI();
  // }, []);

  // useEffect(() => {
  //   const fetchCustomerListAPI = async () => {
  //     try {
  //       const response = await axios.get(customerListAPI);
  //       if (response && response.status === 200) {
  //         const usersLength = response.data.user.length;
  //         console.log(response.data.user.length);
  //         setCustomerLength(usersLength);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching supplier list", error);
  //     }
  //   };
  //   fetchCustomerListAPI();
  // }, []);

  // useEffect(() => {
  //   const fetchProductListAPI = async () => {
  //     const adminLoginResponse = sessionStorage.getItem(
  //       "adminLoginCredentials"
  //     );
  //     const parsedData = JSON.parse(adminLoginResponse);
  //     if (parsedData) {
  //       try {
  //         const response = await axios.get(supplierProductListAPI, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${parsedData.token}`,
  //           },
  //         });
  //         if (response && response.status === 200) {
  //           setProductsListLength(response.data.data.length);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching supplier list", error);
  //       }
  //     }
  //   };
  //   fetchProductListAPI();
  // }, []);

  return (
    <Box margin="0.5rem 1rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" subtitle="Welcome to your dashboard" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "1rem",
              fontWeight: "bold",
              padding: "0.1remem 0.2rem",
              textTransform: "none",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "0.2rem" }} />
            Download Reports
          </Button>
        </Box>
      </Box>
      {/* Progress bars */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="1rem !important"
        gridAutoRows="minmax(100px, auto)"
      >
        {/* first Row */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            navigate("/customer");
          }}
        >
          <StatBox
            // title={customerLength}
            subtitle="Customers"
            // progress="0.50"
            // increase="+21%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            navigate("/supplier");
          }}
        >
          <StatBox
            // title={supplierLength}
            subtitle="Suppliers"
            // progress="0.50"
            // increase="+14%"
            icon={
              <img
                src="icons/businessman.png"
                alt=""
                style={{ width: 25, height: 25 }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            navigate("/category");
          }}
        >
          <StatBox
            // title={productsListLength}
            subtitle="Categories"
            progress="0.30"
            increase="+5%"
            icon={
              <img
                src="icons/product.png"
                alt=""
                style={{ width: 25, height: 25 }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* second row */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          height="50vh !important"
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                $59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px !important" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>
        {/* transactions */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          height="50vh !important"
          overflow="auto !important"
          className="transaction-container"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>
        {/* third row */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
